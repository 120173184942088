import { IBoundingBox } from 'face-api.js/src/classes/BoundingBox';
import { LabeledBox } from 'face-api.js/src/classes/LabeledBox';
import { IRect } from 'face-api.js/src/classes/Rect';
export declare class PredictedBox extends LabeledBox {
    static assertIsValidPredictedBox(box: any, callee: string): void;
    private _score;
    private _classScore;
    constructor(box: IBoundingBox | IRect | any, label: number, score: number, classScore: number);
    get score(): number;
    get classScore(): number;
}
