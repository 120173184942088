import { createBrowserEnv } from 'face-api.js/src/env/createBrowserEnv';
import { createFileSystem } from 'face-api.js/src/env/createFileSystem';
import { createNodejsEnv } from 'face-api.js/src/env/createNodejsEnv';
import { isBrowser } from 'face-api.js/src/env/isBrowser';
import { isNodejs } from 'face-api.js/src/env/isNodejs';
import { Environment } from 'face-api.js/src/env/types';
declare function getEnv(): Environment;
declare function setEnv(env: Environment): void;
declare function initialize(): void;
declare function monkeyPatch(env: Partial<Environment>): void;
export declare const env: {
    getEnv: typeof getEnv;
    setEnv: typeof setEnv;
    initialize: typeof initialize;
    createBrowserEnv: typeof createBrowserEnv;
    createFileSystem: typeof createFileSystem;
    createNodejsEnv: typeof createNodejsEnv;
    monkeyPatch: typeof monkeyPatch;
    isBrowser: typeof isBrowser;
    isNodejs: typeof isNodejs;
};
export * from 'face-api.js/src/env/types';
