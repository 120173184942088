import { FaceDetection } from 'face-api.js/src/classes/FaceDetection';
import { FaceLandmarks } from 'face-api.js/src/classes/FaceLandmarks';
import { FaceLandmarks68 } from 'face-api.js/src/classes/FaceLandmarks68';
import { WithFaceDetection } from 'face-api.js/src/factories/WithFaceDetection';
export declare type WithFaceLandmarks<TSource extends WithFaceDetection<{}>, TFaceLandmarks extends FaceLandmarks = FaceLandmarks68> = TSource & {
    landmarks: TFaceLandmarks;
    unshiftedLandmarks: TFaceLandmarks;
    alignedRect: FaceDetection;
};
export declare function isWithFaceLandmarks(obj: any): obj is WithFaceLandmarks<WithFaceDetection<{}>, FaceLandmarks>;
export declare function extendWithFaceLandmarks<TSource extends WithFaceDetection<{}>, TFaceLandmarks extends FaceLandmarks = FaceLandmarks68>(sourceObj: TSource, unshiftedLandmarks: TFaceLandmarks): WithFaceLandmarks<TSource, TFaceLandmarks>;
