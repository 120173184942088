import * as tf from '@tensorflow/tfjs-core';
import { NetInput, TNetInput } from 'face-api.js/src/dom';
import { FaceFeatureExtractor } from 'face-api.js/src/faceFeatureExtractor/FaceFeatureExtractor';
import { FaceFeatureExtractorParams } from 'face-api.js/src/faceFeatureExtractor/types';
import { FaceProcessor } from 'face-api.js/src/faceProcessor/FaceProcessor';
import { FaceExpressions } from 'face-api.js/src/faceExpressionNet/FaceExpressions';
export declare class FaceExpressionNet extends FaceProcessor<FaceFeatureExtractorParams> {
    constructor(faceFeatureExtractor?: FaceFeatureExtractor);
    forwardInput(input: NetInput | tf.Tensor4D): tf.Tensor2D;
    forward(input: TNetInput): Promise<tf.Tensor2D>;
    predictExpressions(input: TNetInput): Promise<FaceExpressions | FaceExpressions[]>;
    protected getDefaultModelName(): string;
    protected getClassifierChannelsIn(): number;
    protected getClassifierChannelsOut(): number;
}
