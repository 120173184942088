import * as tf from '@tensorflow/tfjs-core';
import { NetInput, TNetInput } from 'face-api.js/src/dom';
import { NeuralNetwork } from 'face-api.js/src/NeuralNetwork';
import { NetParams } from 'face-api.js/src/faceRecognitionNet/types';
export declare class FaceRecognitionNet extends NeuralNetwork<NetParams> {
    constructor();
    forwardInput(input: NetInput): tf.Tensor2D;
    forward(input: TNetInput): Promise<tf.Tensor2D>;
    computeFaceDescriptor(input: TNetInput): Promise<Float32Array | Float32Array[]>;
    protected getDefaultModelName(): string;
    protected extractParamsFromWeigthMap(weightMap: tf.NamedTensorMap): {
        params: NetParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
    protected extractParams(weights: Float32Array): {
        params: NetParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
}
