import * as tf from '@tensorflow/tfjs-core';
import { BoundingBox } from 'face-api.js/src/classes/BoundingBox';
import { Dimensions } from 'face-api.js/src/classes/Dimensions';
import { ObjectDetection } from 'face-api.js/src/classes/ObjectDetection';
import { NetInput } from 'face-api.js/src/dom/NetInput';
import { TNetInput } from 'face-api.js/src/dom/types';
import { NeuralNetwork } from 'face-api.js/src/NeuralNetwork';
import { TinyYolov2Config } from 'face-api.js/src/tinyYolov2/config';
import { ITinyYolov2Options } from 'face-api.js/src/tinyYolov2/TinyYolov2Options';
import { DefaultTinyYolov2NetParams, MobilenetParams, TinyYolov2NetParams } from 'face-api.js/src/tinyYolov2/types';
export declare class TinyYolov2Base extends NeuralNetwork<TinyYolov2NetParams> {
    static DEFAULT_FILTER_SIZES: number[];
    private _config;
    constructor(config: TinyYolov2Config);
    get config(): TinyYolov2Config;
    get withClassScores(): boolean;
    get boxEncodingSize(): number;
    runTinyYolov2(x: tf.Tensor4D, params: DefaultTinyYolov2NetParams): tf.Tensor4D;
    runMobilenet(x: tf.Tensor4D, params: MobilenetParams): tf.Tensor4D;
    forwardInput(input: NetInput, inputSize: number): tf.Tensor4D;
    forward(input: TNetInput, inputSize: number): Promise<tf.Tensor4D>;
    detect(input: TNetInput, forwardParams?: ITinyYolov2Options): Promise<ObjectDetection[]>;
    protected getDefaultModelName(): string;
    protected extractParamsFromWeigthMap(weightMap: tf.NamedTensorMap): {
        params: TinyYolov2NetParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
    protected extractParams(weights: Float32Array): {
        params: TinyYolov2NetParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
    protected extractBoxes(outputTensor: tf.Tensor4D, inputBlobDimensions: Dimensions, scoreThreshold?: number): Promise<{
        row: number;
        col: number;
        anchor: number;
        box: BoundingBox;
        score: number;
        classScore: number;
        label: number;
    }[]>;
    private extractPredictedClass;
}
