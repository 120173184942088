import * as tf from '@tensorflow/tfjs-core';
import { FaceDetection, Point } from 'face-api.js/src/classes';
import { ParamMapping } from 'face-api.js/src/common';
import { TNetInput } from 'face-api.js/src/dom';
import { ITinyYolov2Options } from 'face-api.js/src/tinyYolov2';
import { TinyYolov2Base } from 'face-api.js/src/tinyYolov2/TinyYolov2Base';
import { TinyYolov2NetParams } from 'face-api.js/src/tinyYolov2/types';
export declare class TinyFaceDetector extends TinyYolov2Base {
    constructor();
    get anchors(): Point[];
    locateFaces(input: TNetInput, forwardParams: ITinyYolov2Options): Promise<FaceDetection[]>;
    protected getDefaultModelName(): string;
    protected extractParamsFromWeigthMap(weightMap: tf.NamedTensorMap): {
        params: TinyYolov2NetParams;
        paramMappings: ParamMapping[];
    };
}
