import { FaceDetection } from 'face-api.js/src/classes/FaceDetection';
import { TNetInput } from 'face-api.js/src/dom';
import { ComposableTask } from 'face-api.js/src/globalApi/ComposableTask';
import { DetectAllFaceLandmarksTask, DetectSingleFaceLandmarksTask } from 'face-api.js/src/globalApi/DetectFaceLandmarksTasks';
import { PredictAllAgeAndGenderTask, PredictSingleAgeAndGenderTask } from 'face-api.js/src/globalApi/PredictAgeAndGenderTask';
import { PredictAllFaceExpressionsTask, PredictSingleFaceExpressionsTask } from 'face-api.js/src/globalApi/PredictFaceExpressionsTask';
import { FaceDetectionOptions } from 'face-api.js/src/globalApi/types';
export declare class DetectFacesTaskBase<TReturn> extends ComposableTask<TReturn> {
    protected input: TNetInput;
    protected options: FaceDetectionOptions;
    constructor(input: TNetInput, options?: FaceDetectionOptions);
}
export declare class DetectAllFacesTask extends DetectFacesTaskBase<FaceDetection[]> {
    run(): Promise<FaceDetection[]>;
    private runAndExtendWithFaceDetections;
    withFaceLandmarks(useTinyLandmarkNet?: boolean): DetectAllFaceLandmarksTask<{
        detection: FaceDetection;
    }>;
    withFaceExpressions(): PredictAllFaceExpressionsTask<{
        detection: FaceDetection;
    }>;
    withAgeAndGender(): PredictAllAgeAndGenderTask<{
        detection: FaceDetection;
    }>;
}
export declare class DetectSingleFaceTask extends DetectFacesTaskBase<FaceDetection | undefined> {
    run(): Promise<FaceDetection | undefined>;
    private runAndExtendWithFaceDetection;
    withFaceLandmarks(useTinyLandmarkNet?: boolean): DetectSingleFaceLandmarksTask<{
        detection: FaceDetection;
    }>;
    withFaceExpressions(): PredictSingleFaceExpressionsTask<{
        detection: FaceDetection;
    }>;
    withAgeAndGender(): PredictSingleAgeAndGenderTask<{
        detection: FaceDetection;
    }>;
}
