import * as tf from '@tensorflow/tfjs-core';
import { FaceDetection } from 'face-api.js/src/classes/FaceDetection';
import { NetInput, TNetInput } from 'face-api.js/src/dom';
import { NeuralNetwork } from 'face-api.js/src/NeuralNetwork';
import { ISsdMobilenetv1Options } from 'face-api.js/src/ssdMobilenetv1/SsdMobilenetv1Options';
import { NetParams } from 'face-api.js/src/ssdMobilenetv1/types';
export declare class SsdMobilenetv1 extends NeuralNetwork<NetParams> {
    constructor();
    forwardInput(input: NetInput): {
        boxes: tf.Tensor2D[];
        scores: tf.Tensor1D[];
    };
    forward(input: TNetInput): Promise<{
        boxes: tf.Tensor2D[];
        scores: tf.Tensor1D[];
    }>;
    locateFaces(input: TNetInput, options?: ISsdMobilenetv1Options): Promise<FaceDetection[]>;
    protected getDefaultModelName(): string;
    protected extractParamsFromWeigthMap(weightMap: tf.NamedTensorMap): {
        params: NetParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
    protected extractParams(weights: Float32Array): {
        params: NetParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
}
