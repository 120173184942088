import * as tf from '@tensorflow/tfjs-core';
import { IDimensions } from 'face-api.js/src/classes';
import { FaceLandmarks68 } from 'face-api.js/src/classes/FaceLandmarks68';
import { NetInput, TNetInput } from 'face-api.js/src/dom';
import { FaceFeatureExtractorParams, TinyFaceFeatureExtractorParams } from 'face-api.js/src/faceFeatureExtractor/types';
import { FaceProcessor } from 'face-api.js/src/faceProcessor/FaceProcessor';
export declare abstract class FaceLandmark68NetBase<TExtractorParams extends FaceFeatureExtractorParams | TinyFaceFeatureExtractorParams> extends FaceProcessor<TExtractorParams> {
    postProcess(output: tf.Tensor2D, inputSize: number, originalDimensions: IDimensions[]): tf.Tensor2D;
    forwardInput(input: NetInput): tf.Tensor2D;
    forward(input: TNetInput): Promise<tf.Tensor2D>;
    detectLandmarks(input: TNetInput): Promise<FaceLandmarks68 | FaceLandmarks68[]>;
    protected getClassifierChannelsOut(): number;
}
