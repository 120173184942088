import { Box } from 'face-api.js/src/classes/Box';
import { IDimensions } from 'face-api.js/src/classes/Dimensions';
import { ObjectDetection } from 'face-api.js/src/classes/ObjectDetection';
import { Rect } from 'face-api.js/src/classes/Rect';
export interface IFaceDetecion {
    score: number;
    box: Box;
}
export declare class FaceDetection extends ObjectDetection implements IFaceDetecion {
    constructor(score: number, relativeBox: Rect, imageDims: IDimensions);
    forSize(width: number, height: number): FaceDetection;
}
