import * as tf from '@tensorflow/tfjs-core';
import { NetInput, TNetInput } from 'face-api.js/src/dom';
import { NeuralNetwork } from 'face-api.js/src/NeuralNetwork';
import { IFaceFeatureExtractor, TinyFaceFeatureExtractorParams } from 'face-api.js/src/faceFeatureExtractor/types';
export declare class TinyFaceFeatureExtractor extends NeuralNetwork<TinyFaceFeatureExtractorParams> implements IFaceFeatureExtractor<TinyFaceFeatureExtractorParams> {
    constructor();
    forwardInput(input: NetInput): tf.Tensor4D;
    forward(input: TNetInput): Promise<tf.Tensor4D>;
    protected getDefaultModelName(): string;
    protected extractParamsFromWeigthMap(weightMap: tf.NamedTensorMap): {
        params: TinyFaceFeatureExtractorParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
    protected extractParams(weights: Float32Array): {
        params: TinyFaceFeatureExtractorParams;
        paramMappings: import("face-api.js/src/common").ParamMapping[];
    };
}
